import React from "react";
import {
  FullscreenControl,
  GeolocateControl,
  NavigationControl,
} from "react-map-gl/maplibre";
import "./controls.scss";
import Copyrightattribute from "../copyright_toggle/Copyrightattribute";

const Controls = ({ testingMapLoad }) => {
  return (
    <>
      <NavigationControl
        position="bottom-right"
        style={{ borderRadius: "26.02px" }}
      />
      <GeolocateControl
        style={{ borderRadius: "50%" }}
        position="bottom-right"
        positionOptions={{
          enableHighAccuracy: true,
        }}
        // trackUserLocation:true
      />

      {/* <FullscreenControl
        style={{ borderRadius: "50%" }}
        position="bottom-right"
      /> */}
      <Copyrightattribute testingMapLoad={testingMapLoad} />
    </>
  );
};

export default Controls;
