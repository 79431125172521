import activeUrl from "./activeUrl";

let urls = activeUrl.PRODUCTION_SERVER;
// let urls = activeUrl.LOCAL_SERVER;
const userUrl = activeUrl.REPORTS_URL;
const panoUrl = activeUrl.PANO_URL;
const mapStyleBaseUrl = activeUrl.MAP_STYLE_URL;
const event = activeUrl.EVENT_URL;

// const urls = "https://route-init.gallimap.com/";

// const panoUrl = "https://image-init.gallimap.com/";

const mapUrl = `${urls}styles/light/style.json`;
// const userUrl = "https://reportsapi-uat.gallimap.com/";
const mapStyleUrl = `${mapStyleBaseUrl}styles/light/style.json?accessToken=${process.env.REACT_APP_TOKEN_KEY}`;
const termUrl = "https://gallimaps.com/terms.html";
const privacyUrl = "https://gallimaps.com/privacy.html";

const imageUrl = `${urls}images/`;
const searchFromCurrentLocationUrl = `${urls}searchApi/geojson/currentLocation?accessToken=${process.env.REACT_APP_TOKEN_KEY}`;
const autocompleteUrl = `${urls}searchApi/galliAutoComplete?accessToken=${process.env.REACT_APP_TOKEN_KEY}`;
const searchUrl = `${urls}searchApi/geojson_searchResult?accessToken=${process.env.REACT_APP_TOKEN_KEY}`;
// const autocompleteUrl = `${urls}searchApi/geojson_getAll/search?accessToken=${process.env.REACT_APP_TOKEN_KEY}`;
const reversegeocodingUrl = `${urls}api/v1/reverse/generalReverse?accessToken=${process.env.REACT_APP_TOKEN_KEY}`;
const routingUrl = `${urls}routingAPI?`;
const addBusiness = `${urls}business/addBusiness?accessToken=${process.env.REACT_APP_TOKEN_KEY}`;
const getAllBusinessCategories = `${urls}business/allBusinessCategories?accessToken=${process.env.REACT_APP_TOKEN_KEY}`;
const amenityUrl = `${urls}api/v1/reverse/geoNearest?accessToken=${process.env.REACT_APP_TOKEN_KEY}`;
const panoPointsUrl = `${panoUrl}api/v1/streetmarker/`;
const panoViewUrl = `${panoUrl}api/v1/streetmarker/getnearestimage/`;
const getCodeUrl = `${userUrl}user?phone=`;
const verifyCodeUrl = `${userUrl}verify`;
const updateUserUrl = `${userUrl}`;
const userProfileUrl = `${userUrl}/profile`;
const eventUrl = `${event}api/v1/events`;
const eventImageUrl = `${event}static/`;
const departmentUrl = `${urls}department/departmentSearch?accessToken=${process.env.REACT_APP_TOKEN_KEY}`;
// const checkDepartmentListUrl = `${urls}department/getDepartmentwiseSearch?accessToken=${process.env.REACT_APP_TOKEN_KEY}`;
const checkDepartmentListUrl = `${urls}searchApi/galliSearch?accessToken=${process.env.REACT_APP_TOKEN_KEY}`;

export {
  departmentUrl,
  checkDepartmentListUrl,
  eventImageUrl,
  eventUrl,
  urls,
  searchUrl,
  autocompleteUrl,
  mapUrl,
  imageUrl,
  reversegeocodingUrl,
  routingUrl,
  searchFromCurrentLocationUrl,
  addBusiness,
  getAllBusinessCategories,
  amenityUrl,
  panoPointsUrl,
  panoViewUrl,
  termUrl,
  privacyUrl,
  getCodeUrl,
  verifyCodeUrl,
  updateUserUrl,
  userProfileUrl,
  mapStyleUrl,
};
